<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="product-page">
      <b-container>
        <div v-if="likedImages.length">
          <b-row>
            <product-card
              v-for="product in likedImages"
              :key="product.id"
              :product="product"
              @toggle-like="updateLikeStatus"
               :showShareButton="false"
            />
          </b-row>
        </div>
        <div class="no-data mt-5 mb-5" v-else-if="!isLoading && !likedImages.length">
          <div class="mx-auto text-center">
            <h2>{{ noDataMessage }}</h2>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

  
<script>
import productCard from './imageCard.vue';
import { mapGetters, mapActions } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    productCard,
  },
  data() {
    return {
      isLoading: false,
      likedImages: [],
      noDataMessage: 'No liked images found.',
    };
  },
  computed: {
    ...mapGetters('app', ['isLogged', 'userData']),
    userId() {
      return this.userData?.userId || null;
    },
  },
  created() {
    this.syncUserFromStorage();
  },
  mounted() {
    if (this.isLogged) {
      this.fetchLikedImages();
    } else {
      this.$bvModal.show('modal-login');
    }
  },
  methods: {
    ...mapActions('app', ['syncUserFromStorage']),
    async fetchLikedImages() {
      if (!this.userId) {
        this.$bvModal.show('modal-login');
        return;
      }

      this.isLoading = true;

      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL}getLikedImages?userId=${this.userId}`
        );
        const data = await response.json();

        if (Array.isArray(data.images)) {
          this.likedImages = data.images.map((image) => ({
            id: image.id,
            image_url: image.imageUrl,
            prompt: image.prompt,
            like_or_dislike: image.like_or_dislike,
            timestamp: image.timestamp,
          }));
        } else {
          console.error('Expected an array of liked images, but got:', data.images);
        }
      } catch (error) {
        console.error('Error fetching liked images:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching liked images. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateLikeStatus(imageId, likeStatus) {
      if (!this.userId) {
        this.$bvModal.show('modal-login');
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/updateLikeStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: this.userId, imageId, likeStatus }),
        });

        const data = await response.json();

        if (data.message === 'Like status updated successfully!') {
          const image = this.likedImages.find((img) => img.id === imageId);
          if (image) {
            image.like_or_dislike = likeStatus;
            if (likeStatus === 'N') {
              this.likedImages = this.likedImages.filter((img) => img.id !== imageId);
            }
          }
        }
      } catch (error) {
        console.error('Error updating like status:', error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating like status. Please try again later.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
  watch: {
    isLogged(newValue) {
      if (newValue) {
        this.fetchLikedImages();
      } else {
        this.likedImages = [];
      }
    },
  },
};
</script>

  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  .swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 2px !important;
    padding-bottom: 15px !important;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  </style>
  